.dropzone-box {
  width: 132px;
  height: 132px;
  background: #efefef;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
}

.react-datepicker-wrapper {
  flex: 1 1 auto;
}

.border-f-none:focus {
  outline: none;
}

.password-rounded-end {
  border-top-right-radius: 1.25rem !important;
  border-bottom-right-radius: 1.25rem !important;
}

.dropZone:focus {
  outline: -webkit-focus-ring-color auto 1px !important;
}

.dropZone-error {
  outline: rgba(#2684ff, 1) auto 1px !important;
}

.u-hover-black:hover {
  color: black !important;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}
