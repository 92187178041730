input::placeholder {
  color: #b0bac1 !important;
  font-family: 'DIN2014-DemiBold' !important;
}

.alert-success {
  color: #fff;
  background-color:green;
  border-color:green;
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}  

.alert-primary {
  color: #fff;
  background-color: #F75676;
  border-color: #F75676;
}
.alert-primary-fail {
  color: #fff;
  background-color:#F75676;
  border-color:#F75676;
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.alert-primary-fail-a {
  color: #fff;
}
.alert-primary-fail-a:hover {
  color: #fff;
}

.modal-header {
  border-bottom: 0px solid #dee2e6 !important;
}

.card-login {
  padding: 2rem;
}

.fade:not(.show) {
  display: none;
}

.reset-password .modal-dialog {
    max-width: 100%;
    margin-top: 10% !important;
    width: 30% !important;
    display: inline-block;
}
@media only screen and (max-width: 675px) {
  .reset-password .modal-dialog {
    max-width: 100%;
    margin-top: 10% !important;
    width: 40% !important;
    display: inline-block;
}
}