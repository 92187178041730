#right{
    border:2px solid black;
    box-shadow:0 0px 0px 1px #392;
    background-color:#392;
    color: green;
    appearance:none;
    border-radius:50%;
    width:12px;
    height:12px;
    background-color:#fff;
  
  }
  #right:checked{
    background-image: url(./Right.svg);
    background-position:center;
  }

  #wrong{
    border:2px solid white;
    box-shadow:0 0 0 1px rgb(202, 49, 21);
    appearance:none;
    border-radius:50%;
    width:12px;
    height:12px;
    background-color:#fff;
  
  }
  #wrong:checked{
    background-image: url(./Wrong.svg);
    background-position:center;
  }