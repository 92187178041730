.btnback:hover {
    color: #6c757d !important;
    background-color: #efefef !important;
  }

  .ff-din-regular-2 {
    font-family: 'DIN2014-DemiBold' !important;
    // font-weight: 400 !important;
  }

  .hover-color:hover{
    color: #2f80ed !important;
  }

  .text-clr {
    font-size: 25px;
    margin-left: -10px;
    color: #000 !important;;
  }

  
  .text-blk {
    color: #000 !important;;
  }

  .text-top {
    margin-top: 16px;
  }