input::placeholder,
textarea::placeholder {
  color: #b0bac1 !important;
  font-family: 'DIN2014-DemiBold' !important;
}

.alert-primary {
  color: #fff;
  background-color: #F75676;
  border-color: #F75676;
}

.alert-primary-fail {
  color: #fff;
  background-color: #F75676;
  border-color: #F75676;
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.alert-primary-fail-a {
  color: #fff;
}

.alert-primary-fail-a:hover {
  color: #fff;
  cursor: pointer;
}

.backgroundSucess {
  color: #fff;
  background-color: #4abb79;
  border-color: #4abb79;
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid;
  border-radius: 0.375rem;
  font-size: 0.9rem;
}

.backgroundError {
  color: #fff;
  background-color: #f75676;
  border-color: green;
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid;
  border-radius: 0.375rem;
  font-size: 0.9rem;
}

.modal-header {
  border-bottom: 0px solid #dee2e6 !important;
}

.card-login {
  padding: 2rem;
}

.fade:not(.show) {
  display: none;
}

.text-underline {
  text-decoration: underline;
}

.reset-password .modal-dialog {
  max-width: 100%;
  margin-top: 10% !important;
  width: 30% !important;
  display: inline-block;
}

.card-link {
  color: #2f80ed !important;
}

.css-fr5ril {
  font-size: 22px !important;
  letter-spacing: 15px;
  border-color: rgb(143, 143, 143) !important;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 0.875rem 3rem !important;
  line-height: 1.5 !important;
  border-radius: 40px !important;
  font-size: 1rem !important;
}

@media only screen and (max-width: 675px) {
  .reset-password .modal-dialog {
    max-width: 100%;
    margin-top: 10% !important;
    width: 40% !important;
    display: inline-block;
  }
}
