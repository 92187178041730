.view-people .modal-dialog {
  max-width: 100%;
  display: inline-block;
  margin-bottom: 10%;
  width: 80% !important;
}

.edit-people .modal-dialog {
  max-width: 100%;
  width: 40% !important;
  display: inline-block;
  margin-bottom: 10%;
}
#user-view-modal > .modal-content {
  padding: 16px;
  height: 120%;
  // width: 40% !important;
}

#user-edit-modal-body {
  overflow-y: hidden;
}
#user-view-modal-body {
  overflow-y: auto;
}

.edit-button-emp {
  &:hover {
    cursor: pointer;
  }
}

.view-employee-modal-buttons {
  display: flex;
  margin: 0 16px 7px auto;
  gap: 16px;
  i {
    &:hover {
      cursor: pointer;
    }
  }
}
