.form-control {
    display: block;
    width: 100%;
    height: calc(2.75rem + 2px);
    padding: 0.625rem 0.75rem;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  input::-webkit-input-placeholder {
    font-weight: 600;
  }
  
  .form-check-label {
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .form-check-input {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 7px;
  }