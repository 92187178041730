.acknowledge {
  .card-header {
    padding: 1.25rem 2rem;
    margin-bottom: 0;
    background-color: #fff;
    border: none;
  }

  .lead {
    line-height: 1.7;
    margin-top: 1.5rem;
    font-size: 1.25rem;
    font-weight: 300;
    font-family: "DIN 2014", sans-serif;
  }

  .card-body {
    padding-top: 0px !important;
    padding: 2em;
  }

  .text-muted {
    color: #727272 !important;
  }

  .desc {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.7;
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: "DIN 2014", sans-serif;
  }

  .listbox {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-bottom: 1.5em;
  }

  .card-body .listbox li {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-align: left;
    padding: 1em;
    margin-bottom: 1em;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    border-radius: 8px;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  .card-text:last-child {
    margin-bottom: 0;
  }

  .card-text {
    font-family: "DIN 2014", sans-serif;
  }

  .card-footer {
    border-top: none;
    padding: 1.25rem 2rem;
    background-color: #fff;
  }

  .card-footer:last-child {
    border-radius: 0 0 calc(8px - 1px) calc(8px - 1px);
  }

  .btn-primary {
    color: #fff;
  }
}
