.dropzone-box {
  width: 132px;
  height: 132px;
  background: #efefef;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
}

.react-datepicker-wrapper {
  flex: 1 1 auto;
}

.border-f-none:focus {
  outline: none;
}

.password-rounded-end {
  border-top-right-radius: 1.25rem !important;
  border-bottom-right-radius: 1.25rem !important;
}

.featured-video-label-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;

  .tangi-icon-container svg.tangi-icon-svg {
    width: 10.5px;
    height: 10.5px;
  }
}

.alternative-link-input-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
