.skeleton-max-container {
  width: 100%;
  height: 100%;
  margin-top: -8px;
}

$ROW_HEIGHT_UNITS: 5;
$ROW_HEIGHT: 432 / $ROW_HEIGHT_UNITS;
$NUMBER_OF_COLUMNS: 3;
$GAP: 16;
.dashboard-layout-container {
  display: grid;
  grid-template-columns: repeat(#{$NUMBER_OF_COLUMNS}, 1fr);
  grid-gap: 16px;
  width: 1300px;
  .widget_asset_by_type {
    grid-column: 1 / span 1;
    grid-row: 1 / span #{$ROW_HEIGHT_UNITS};
    height: calc(#{$ROW_HEIGHT * $ROW_HEIGHT_UNITS}px);
    position: relative;
  }
  .widget_asset_over_time {
    grid-column: 2 / span 2;
    grid-row: 1 / span #{$ROW_HEIGHT_UNITS};
    height: calc(#{$ROW_HEIGHT * $ROW_HEIGHT_UNITS}px);
    position: relative;
  }
  .widget_data_by_departments {
    grid-column: 1 / span 1;
    grid-row: #{$ROW_HEIGHT_UNITS + 1} / span #{$ROW_HEIGHT_UNITS};
    height: calc(#{$ROW_HEIGHT * $ROW_HEIGHT_UNITS}px);
    position: relative;
  }
  .widget_assets_data {
    grid-column: 2 / span 1;
    grid-row: #{$ROW_HEIGHT_UNITS + 1} / span 3;
    height: calc(#{$ROW_HEIGHT * 3}px);
    position: relative;
  }
  .widget_users {
    grid-column: 2 / span 1;
    grid-row: #{$ROW_HEIGHT_UNITS + 4} / span 2;
    height: calc(#{$ROW_HEIGHT * 2}px);
    position: relative;
  }
  .widget_insights {
    grid-column: 3 / span 1;
    grid-row: #{$ROW_HEIGHT_UNITS + 1} / span #{$ROW_HEIGHT_UNITS};
    height: calc(#{$ROW_HEIGHT * $ROW_HEIGHT_UNITS + $GAP}px);
    position: relative;
  }
  .widget_projects_by_assets {
    grid-column: 1 / span 1;
    grid-row: #{($ROW_HEIGHT_UNITS * 2) + 1} / span #{$ROW_HEIGHT_UNITS};
    height: calc(#{$ROW_HEIGHT * $ROW_HEIGHT_UNITS + $GAP}px);
    position: relative;
  }
  .widget_contributors {
    grid-column: 2 / span 1;
    grid-row: #{$ROW_HEIGHT_UNITS * 2 + 1} / span #{$ROW_HEIGHT_UNITS};
    height: calc(#{$ROW_HEIGHT * $ROW_HEIGHT_UNITS + $GAP}px);
    position: relative;
  }
  .widget_people_status {
    grid-column: 2 / span 1;
    grid-row: #{$ROW_HEIGHT_UNITS * 3 + 1} / span 2;
    height: calc(#{$ROW_HEIGHT * 2}px);
    position: relative;
  }
  .widget_used_tags {
    grid-column: 3 / span 1;
    grid-row: #{($ROW_HEIGHT_UNITS * 2) + 1} / span #{$ROW_HEIGHT_UNITS};
    height: calc(#{$ROW_HEIGHT * $ROW_HEIGHT_UNITS + $GAP}px);
    position: relative;
  }
  .widget_people_by_role {
    grid-column: 1 / span 1;
    grid-row: #{$ROW_HEIGHT_UNITS * 3 + 1} / span 2;
    height: calc(#{$ROW_HEIGHT * 2}px);
    position: relative;
  }
}

.react-grid-item.react-grid-placeholder {
  background-color: #0066FF;
  border-radius: 8px;
}
