.Header-top {
  padding-top: 3rem !important;
  background-color: #fff;
}

.Content-txt {
  padding-left: 5%;
}

.custom-map-modal {
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }
}

.profile-dropdown-toggle a:after {
  display: none;
}

.profile-dropdown-toggle a:hover {
  color: #000 !important;
}

.header-dropdown {
  padding: 16px;
  font-size: 14px !important;

  &:hover {
    background-color: #f6f9fc;
  }
}

.header-dropdown-m {
  padding: 7.5px;
  padding-left: 20px;

  &:hover {
    background-color: #f6f9fc;
  }
}

.dropdown-menu {
  border: 0px !important;
  border-radius: 0.5rem !important;
  box-shadow: 1px 6px 25px -1.5px #00000031 !important;
  min-width: 15rem !important;
  right: -40px;
  left: revert !important;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navbar-text-small {
  color: gray;
  font-size: 12px;
}

#header-nav-container {
  width: 100%;
  padding-inline: 32px 3%;
}
#nav-dropdown1 {
  padding-right: 0;
}

.navbar {
  padding-block: 5px;
}
