.add-btn:hover {
  top: -2px;
  color: #000 !important;
  transition: unset !important;
  background-color: #efefef;
}

.btn-secondary {
  color: #000;
  background-color: #efefef;
  border-color: #efefef;
}

.nav-links:hover {
  color: #000 !important;
  background-color: #efefef !important;
  border-color: #efefef !important;
}

.text-ellipsis {
  white-space: nowrap !important;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#invite-modal-header {
  color: black;
  font-family: 'DIN2014-REGULAR';
  font-size: 24px;
  font-weight: 400;
}

.overflow-fix {
  > div {
    overflow: visible !important;
  }
}
