.h-20 {
  height: 20px !important;
}

.zoom-90 {
  zoom: 90% !important;
}

.table-response {
  overflow-x: auto;
  width: 100%;
}