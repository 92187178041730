$primary: #2f80ed;

@import '~bootstrap/scss/bootstrap';
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

@font-face {
  font-family: 'DIN2014-Bold';
  src: local('DIN 2014'), url(../assets/fonts/DIN2014-Bold.woff) format('woff'), url(../assets/fonts/DIN2014-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'DIN2014-Regular';
  src: local('DIN 2014'), url(../assets/fonts/DIN2014-Regular.woff) format('woff'), url(../assets/fonts/DIN2014-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DIN2014-DemiBold';
  src: local('DIN 2014'), url(../assets/fonts/DIN2014-DemiBold.woff) format('woff'), url(../assets/fonts/DIN2014-DemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'BankGothic Md BT';
  src: local('BankGothic Md BT'), url(../assets/fonts/BankGothicMedium.woff) format('woff'), url(../assets/fonts/BankGothicMedium.ttf) format('truetype');
}
@font-face {
  font-family: 'NotoSansJP-Regular';
  src: local('NotoSansJP-Regular'), url(../assets/fonts/NotoSansJP-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-weight: 400;
  src: local('Roboto'), url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-weight: 600;
  src: local('Roboto-Medium'), url('../assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-weight: 700;
  src: local('Roboto-Bold'), url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
}

.ff-din-regular {
  font-family: 'DIN2014-Regular';
}

.lowerCase {
  text-transform: lowercase;
}

.ff-din-DemiBold {
  font-family: 'DIN2014-DemiBold';
}

.leftAlign {
  text-align: left;
}

.ff-din-bold {
  font-family: 'DIN2014-Bold';
}

body {
  font-family: 'DIN2014-Bold';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #f8f9fa;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
}

.h1,
h1 {
  font-size: 1.625rem;
}

.h2,
h2 {
  font-size: 1.25rem;
}

.h3,
h3 {
  font-size: 1.0625rem;
}

.h4,
h4 {
  font-size: 0.9375rem;
}

.h5,
h5 {
  font-size: 0.8125rem;
}

.h6,
h6 {
  font-size: 0.625rem;
}

a {
  text-decoration: none;
}

.shadow {
  box-shadow: 0 0 2rem 0 rgba(137, 139, 141, 0.15) !important;
}

.fw-600 {
  font-weight: 600;
}

.border-none {
  border: none;
}

.bg-body {
  background-color: #fbfbfb !important;
}

.fs-7 {
  font-size: 0.875rem;
}

.bg-secondary {
  background-color: #efefef !important;
}

.icon-img {
  height: 30px;
  width: 55px;
}

.px-39 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mt-15 {
  margin-top: 5rem !important;
}

.mrx-4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.prx-4 {
  padding: 1.25rem 2rem !important;
}

.mtt-5 {
  margin-top: 5.5rem !important;
}

.text-black {
  color: #000;
}

.text-execute {
  color: #a0a0a0;
}

.table-cell-ff {
  font-family: 'DIN2014-DemiBold';
  font-size: 0.8125rem;
  white-space: normal !important;
}

.hand-cursor {
  cursor: pointer;
}

.editor-overflow {
  overflow: hidden;
}

.btn {
  transition: unset !important;
}

.text-overflow-hide {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.text-muted {
  color: #727272 !important;
}

@media print {
  .rdt_Pagination {
    display: none;
  }
}

.u-focus-black {
  color: #202020 !important;
}

.btn-secondary:focus {
  background-color: #efefef;
  border: 1px solid #c1c6ca;
  color: #000000;
}

.admin-table-textwrap {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disable-hov {
  &:hover {
    animation: none !important;
    box-shadow: none !important;
  }
}
.tangi-selector__control {
  padding: 4px 0px;
}

.tangi-selector-error__control {
  border-color: red !important;
  padding: 4px 0px;
}

// --- tables ---
.rdt_TableCol {
  font-weight: bolder !important;
}

.rdt_TableCol_Sortable {
  white-space: nowrap !important;
  font-family: inherit !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  font-size: 0.8125rem !important;
}

.rdt_TableHeadRow {
  border-top: 2px solid #c1c6ca !important;
  border-bottom: 2px solid #c1c6ca !important;
  align-items: center !important;
  letter-spacing: 1px !important;
  border-color: transparent !important;
}

// --- modals ---
.modal.partnership-modal,
.modal.partner-modal {
  display: flex !important;
  align-items: center;
}

// TODO: do we need this?
small,
.small {
  font-size: 80%;
  font-weight: 400;
}

.content-height {
  min-height: calc(100vh - 200px);
}

.navbar-light .navbar-nav .nav-link:hover {
  color: black !important;
}

.navbar-light .navbar-nav .nav-link {
  color: black !important;
}

.content-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-home {
  width: 120px;
}

.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.625rem 0.85rem;
  line-height: 1.5;
  border-radius: 40px;
  position: relative;
  text-transform: none;
  transition: all 0.15s ease;
  letter-spacing: 0.1em;
  font-size: 0.875rem;
  will-change: transform;
}

.f-home-content {
  font-size: 1.625rem;
}

.u-rm-shadom-animation {
  animation: none !important;
  box-shadow: none !important;
}

.blue-icon {
  color: #306fc1;
}
