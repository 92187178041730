.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  word-wrap: normal;
  font-family: 'DIN2014-Regular';
  overflow: hidden; 
}

.lettered-avatar {
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  color: rgb(255, 255, 255);
  border-radius: 28px;
}

.avatar img {
  object-fit: contain
}

.avatar-sm {
  width: 24px;
  height: 24px;
  font-size: 14px;
  // padding-top: 2px;
}

.avatar-widget {
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 28px;
  font-weight: 200 !important;
  font-family: 'DIN2014-Regular';
}

.avatar-xs {
  width: 30px;
  height: 30px;
}
