a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #2f80ed;
}

.mt7 {
  margin-top: 1.75rem !important;
}

.card-rounded-2 {
  border-radius: calc(0.75rem - 2px);
  height: 15rem;
}

.card-rounded-22 {
  border-radius: calc(0.75rem - 2px);
}

.fileViewer {
  >.modal-dialog {
    max-width: 1000px;
    width: 100% !important;
  }
}

.btnreload {
  color: #000 !important;
  background-color: #efefef !important;
}

.btnreload:hover {
  color: #000 !important;
  background-color: #efefef !important;
}

.modal-header>.close {
  border: none;
  background: none;
  font-size: 30px;
  padding: 0px;
  padding-right: 10px;
}

.modal-dialog {
  /* Width */
  max-width: 100%;
  display: inline-block;
}

.modal {
  z-index: -1;
  display: flex !important;
}

.modal-open .modal {
  z-index: 1050;
}

.hover-text-muted:hover {
  color: #6c757d !important;
}

.btn-secondary {
  border: 0px;
}

.btn-secondary:hover {
  background-color: #efefef;
}
