.progress {
  display: flex;
  font-size: 0.75rem;
  height: 8px;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 0.25rem;
  background-color: #e9ecef;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) !important;
}

.list-group-item {
  padding: 1rem 1rem !important;
}

.btn-secondary:hover {
  color: black;
}

.form-control:focus {
  box-shadow: none !important;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dd3249;
}

.icon-big {
  font-size: 2em;
}

.remove-btn {
  padding: 15px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    color: black;
    box-shadow: none;
  }
}

.dropZone {
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px !important;
  }
}

.form-btn {
  font-size: 1rem !important;
  padding-top: 0.875rem !important;
  padding-bottom: 1rem !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.back-btn:hover {
  color: black !important;
}

.del-btnt:hover {
  color: #000 !important;
  background-color: #efefef !important;
}

.asset-delete-confirm {
  background-color: #f5365c !important;
  padding: 10px !important;
  color: white !important;
  border-radius: 10px !important;
  border-style: none !important;

  :hover {
    color: white !important;
  }
}

.asset-delete-cancel {
  letter-spacing: 0.4px;
  border-radius: 40px;
  font-size: 0.875rem;
  padding: 0.625rem 0.85rem;
  background-color: #efefef;
  border-color: #efefef;
  border-radius: 10px !important;
  color: #000 !important;

  &:hover {
    color: #000 !important;
  }
}

.delete-confirm .modal-dialog {
  max-width: 100%;
  width: 35% !important;
  display: inline-block;
}

.create-asset.close-btn-pop span {
  color: #ed1c24;
}

.ref-field {
  width: 50%;
}
.ref-container {
  width: 90%;
  display: flex;
  gap: 16px;
}

.url-field {
  width: 100%;
}
.url-container {
  display: flex;
  width: 100%;
}

.url-labels {
  display: flex;
  gap: 40%;
  width: 100%;
  margin-bottom: -13px;
}
.add-url-fields {
  display: flex;
  flex: 0.9;
  gap: 16px;
}
.add-url-button {
  margin-top: 16px;
  flex: 0.1;
  button {
    margin-left: 16px;
  }
}

.text {
  display: block;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
