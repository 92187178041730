.btn {
  letter-spacing: 0.4px;
  border-radius: 40px;
  font-size: 0.875rem;
  padding: 0.625rem 0.85rem;
}

.btnn:hover {
  color: #000 !important;
  background-color: #efefef !important;
}

.btn-muted {
  color: #6c757d !important;
}

.btn-muted:hover {
  color: #6c757d !important;
}

.btn-search {
  color: #000 !important;
  background-color: #efefef !important;
}

.btnn {
  color: #000;
  background-color: #efefef;
}

.btn-secondary:focus {
  background-color: #efefef;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  color: #000;
  background-color: #d6d6d6;
  border-color: #efefef;
}

i {
  line-height: 1 !important;
}

// .btn:hover {
//   animation: pulse 1s;
//   box-shadow: 0 0 0 1em rgba(47, 128, 237, 0.20);
// }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

:focus-visible {
  outline: none !important;
}

.bg-color {
  background-color: rgb(239, 239, 239);
}

// .my-modal {
//   width: 90vw    /* Occupy the 90% of the screen width */
// }

// .custom-modal-style {
//   width: 897px !important;
//   height: 802px !important;
//   margin-left: 170px !important;
// }

.invite-align {
  margin-left: 10px !important;
}

.cancel-color {
  background-color: #f7f1f1 !important;
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 1000px !important;
    width: 81% !important;
  }

  .modal-lg .modal-body {
    padding: 1.25rem !important;
  }
}

.modal-asset .close-btn-pop {
  font-size: 1.85rem !important;
  margin-top: -0.5em;
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 380px;
  }
}

.modal-backdrop {
  z-index: 1050;
}

.modal-footer {
  border-top: 0px solid #dee2e6;
}
.batch-dropdown {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  z-index: 100;
  font-family: DIN Alternate;
  font-size: 15px;
}
.batch-dropdown-item {
  padding: 10px;
  &:hover {
    cursor: pointer;
    background-color: #efefef;
  }
}
