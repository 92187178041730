.content-height {
  min-height: calc(100vh - 200px);
}

.disclaimer .modal-dialog {
  max-width: 100%;
  margin-top: 10% !important;
  width: 30% !important;
  display: inline-block;
}

@media only screen and (max-width: 675px) {
  .disclaimer .modal-dialog {
    max-width: 100%;
    margin-top: 10% !important;
    width: 40% !important;
    display: inline-block;
  }
}
