.img-thumbnail-client-single {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.widget-gap {
  --bs-gutter-x: 2.1rem;
  --bs-gutter-y: 2.1rem;
}
