.dropzoneAgreement {
  width: 100%;
  height: 25%;
  background: #efefef;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
}

.react-datepicker-wrapper {
  flex: 1 1 auto;
}

.border-f-none:focus {
  outline: none;
}

.password-rounded-end {
  border-top-right-radius: 1.25rem !important;
  border-bottom-right-radius: 1.25rem !important;
}

.agreement-container {
  font-size: 12px !important;
}
